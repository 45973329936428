import React from 'react';
import '../../css/Createstrtegy css/Secondsection.css';
import { useState } from 'react';

const Secondsection = ({ setStrategy, strategy, setOrders, orders }) => {
	const [order, setOrder] = useState({
		type: null,
		strick_qty: null,
		stp_perc: null,
		strick_type: null,
		order_type: null,
		strike_expiry: null,
	});
	const orderchange = (name, e, index) => {
		const updatedOrder = orders.map((obj, i) => {
			// 👇️ if id equals 2, update country property
			if (i === index) {
				return { ...obj, [name]: e.target.value };
			} else {
				return obj;
			}
		});
		console.log(updatedOrder);
		setOrders(updatedOrder);
	};
	const deleteleg = (leg_index) => {
		console.log(leg_index);
		if (orders.length === 1) {
			alert('atleast single order must be there');
		} else {
			var filteredorders = [];
			orders.map((item, i) => {
				if (i === leg_index) {
					return null;
				} else {
					filteredorders = [...filteredorders, item];
				}
			});

			setOrders(filteredorders);
		}
	};

	return (
		<div>
			<div id="create_strategy_group_type">
				<div className="containe">
					<div className=" secondcard">
						<div className="new_row">
							<div className="new_row_label">
								<label>Group Type</label>
							</div>
							<div className="">
								<div className="btn_group">
									<button
										onclick="hideFunction()"
										className="single_btn active font"
									>
										Single
									</button>
									<button onclick="showFunction()" className="pair-btn font">
										Pair
									</button>
								</div>
							</div>
						</div>
						<div className="new_row">
							<div className="new_row_label">
								<label>Instruments</label>
							</div>
							<div className="">
								<div
									className="input_group"
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<select
										className="single_btn font"
										name="segments"
										onChange={(e) => {
											setStrategy({
												...strategy,
												['segments']: e.target.value,
											});
										}}
									>
										<option vlaue="nfo">NFO</option>
										<option vlaue="equity">EQUITY</option>
									</select>
									<label style={{ fonntSize: '12px', padding: '0px 8px' }}>
										{' '}
										indices
									</label>
									<select
										className="single_btn font"
										name="indices"
										id="indices"
										onChange={(e) => {
											setStrategy({
												...strategy,
												['indices']: e.target.value,
											});
										}}
									>
										<option vlaue="nifty">NIFTY</option>
										<option vlaue="banknifty">BANKNIFTY</option>
									</select>
								</div>
							</div>
						</div>
						<div className="new_row">
							<div className="new_row_label">
								<label>Adjustment</label>
							</div>
							<div className="">
								<div className="btn_check">
									<div>
										<input
											type="checkbox"
											id="isScheduled"
											onChange={(e) => {
												setStrategy({
													...strategy,
													['isScheduled']: e.target.checked,
												});
											}}
										/>
										<label htmlFor="isScheduled">Is Scheduled</label>
									</div>
									<div>
										<input
											type="checkbox"
											id="ispower_cool"
											onChange={(e) => {
												setStrategy({
													...strategy,
													['ispowercool']: e.target.checked,
												});
											}}
										/>
										<label htmlFor="ispower_cool">Is Powercool</label>
									</div>

									<div>
										<input
											type="checkbox"
											id="re_entry"
											onChange={(e) => {
												setStrategy({
													...strategy,
													['re_entry']: e.target.checked,
												});
											}}
										/>
										<label htmlFor="re_entry">Re-Entry</label>
									</div>

									<div>
										<input
											type="checkbox"
											id="move_sl_to_cost"
											onChange={(e) => {
												setStrategy({
													...strategy,
													['move_sl_to_cost']: e.target.checked,
												});
											}}
										/>
										<label htmlFor="move_sl_to_cost">Move SL to Cost</label>
									</div>
								</div>
							</div>
						</div>
						<div className="card place_config">
							<div className="signal_config">
								<div className="trans_config">
									<span
										className="material-symbols-outlined"
										style={{
											marginRight: 5,
											fontSize: 24,
											verticalAlign: 'bottom',
										}}
									>
										settings_input_component
									</span>
									<span>Transaction Details</span>
								</div>
							</div>
							<div className="transation_builder">
								<div className="options_strike">
									<div className="options_cycle">
										<label className="">Options expiry cycle</label>
										<div className="btn_cycle">
											<button className="font">Weekly</button>
											<button className="font active">Monthly</button>
										</div>
									</div>
									<div className="strike_cycle">
										<label className="">Strike Based On</label>
										<div className="">
											<select
												className="font brd"
												onChange={(e) => {
													setStrategy({
														...strategy,
														['strike_based_on']: e.target.value,
													});
												}}
											>
												<option vlaue="Index">Index</option>
												<option vlaue="Premium">Premium</option>
											</select>
										</div>
									</div>
								</div>
								{orders.length === 0 ? (
									<h3>no orders yet</h3>
								) : (
									orders.map((item, i) => {
										return (
											<div className="table_section">
												<table>
													<thead>
														<tr>
															<th>Transaction</th>
															<th>Option Type</th>
															<th>Strike Index</th>
															<th>Total Lot</th>
															<th>Action Type</th>
															<th>Transaction Stop Loss</th>
															<th>Take Profit</th>
															<th>Delete</th>
														</tr>
													</thead>
													<tbody id="table_body">
														<tr>
															<td>
																<select className="font brd">
																	<option vlaue="Future & Options">
																		Future &amp; Options
																	</option>
																	<option vlaue="Equity & Index">
																		Equity &amp; Index
																	</option>
																</select>
																{/* <div className="btn_groups">
																	<button
																		className="active table_btn"
																		onclick="myFunction(this)"
																	>
																		Option
																	</button>
																	<button
																		className="table_btn"
																		onclick="myFunction(this)"
																	>
																		Future
																	</button>
																	<button
																		className="table_btn"
																		onclick="myFunction(this)"
																	>
																		Equity
																	</button>
																</div> */}
															</td>
															<td>
																<div
																	className="option_type"
																	style={{ display: 'flex' }}
																>
																	{item.strick_type === 'CE' ? (
																		<input
																			type="radio"
																			id={`ce${i}`}
																			name={`strick_type${i}`}
																			value="CE"
																			onChange={(e) => {
																				orderchange('strick_type', e, i);
																			}}
																			checked
																		/>
																	) : (
																		<input
																			type="radio"
																			id={`ce${i}`}
																			name={`strick_type${i}`}
																			value="CE"
																			onChange={(e) => {
																				orderchange('strick_type', e, i);
																			}}
																		/>
																	)}

																	<label
																		htmlFor={`ce${i}`}
																		style={{ marginTop: 'auto' }}
																	>
																		CE
																	</label>
																	<br />
																	{item.strick_type === 'PE' ? (
																		<input
																			type="radio"
																			id={`pe${i}`}
																			name={`strick_type${i}`}
																			value="PE"
																			onChange={(e) => {
																				orderchange('strick_type', e, i);
																			}}
																			checked
																		/>
																	) : (
																		<input
																			type="radio"
																			id={`pe${i}`}
																			name={`strick_type${i}`}
																			value="PE"
																			onChange={(e) => {
																				orderchange('strick_type', e, i);
																			}}
																		/>
																	)}

																	<label
																		htmlFor={`pe${i}`}
																		style={{ marginTop: 'auto' }}
																	>
																		PE
																	</label>
																	<br />
																</div>
															</td>
															<td>
																{strategy.strike_based_on === 'Premium' ? (
																	<input
																		type="text"
																		className="ordertable_in"
																		placeholder="0"
																	/>
																) : (
																	<div className="">
																		<select
																			className="brd"
																			name="type"
																			value={item.type}
																			onChange={(e) => {
																				orderchange('type', e, i);
																			}}
																		>
																			<option text="Future & Options">
																				ATM
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 10
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 9
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 8
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 7
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 6
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 5
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 4
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 3
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 2
																			</option>
																			<option vlaue="Equity & Index">
																				OTM 1
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 10
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 2
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 3
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 4
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 5
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 6
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 7
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 8
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 9
																			</option>
																			<option vlaue="Equity & Index">
																				ITM 10
																			</option>
																		</select>
																	</div>
																)}
															</td>
															<td>
																<div className="total_lot">
																	<input
																		className="ordertable_in"
																		name="strick_qty"
																		value={item.strick_qty}
																		onChange={(e) => {
																			orderchange('strick_qty', e, i);
																		}}
																	/>
																</div>
															</td>
															<td>
																<div
																	className="action_type"
																	style={{ display: 'flex' }}
																>
																	{item.order_type === 'buy' ? (
																		<input
																			type="radio"
																			id={`buy${i}`}
																			name={`order_type${i}`}
																			value="buy"
																			onChange={(e) => {
																				orderchange('order_type', e, i);
																			}}
																			checked
																		/>
																	) : (
																		<input
																			type="radio"
																			id={`buy${i}`}
																			name={`order_type${i}`}
																			value="buy"
																			onChange={(e) => {
																				orderchange('order_type', e, i);
																			}}
																		/>
																	)}

																	<label
																		htmlFor={`buy${i}`}
																		style={{ marginTop: 'auto' }}
																	>
																		BUY
																	</label>
																	<br />
																	{item.order_type === 'sell' ? (
																		<input
																			type="radio"
																			id={`sell${i}`}
																			name={`order_type${i}`}
																			value="sell"
																			onChange={(e) => {
																				orderchange('order_type', e, i);
																			}}
																			checked
																			// defaultValue="SELL"
																		/>
																	) : (
																		<input
																			type="radio"
																			id={`sell${i}`}
																			name={`order_type${i}`}
																			value="sell"
																			onChange={(e) => {
																				orderchange('order_type', e, i);
																			}}
																			// defaultValue="SELL"
																		/>
																	)}

																	<label
																		htmlFor={`sell${i}`}
																		style={{ marginTop: 'auto' }}
																	>
																		SELL
																	</label>
																	<br />
																</div>
															</td>
															<td>
																<div className="align">
																	<div className="align_input_group">
																		<div className="btn_group">
																			<button className="single_btn">%</button>
																			<button className="pair_btn">₹</button>
																			<input
																				className="stop_loss"
																				placeholder="percentage"
																			/>
																		</div>
																	</div>
																</div>
															</td>
															<td>
																<div className="align">
																	<div className="align_input_group">
																		<div className="btn_group">
																			<button className="single_btn">%</button>
																			<button className="pair_btn">₹</button>
																			<input
																				className="stop_loss"
																				placeholder=""
																				value=""
																			/>
																		</div>
																	</div>
																</div>
															</td>
															<td>
																<div className="action_btn">
																	<button
																		className="delete_btn"
																		onClick={() => {
																			deleteleg(i);
																		}}
																	>
																		<span className="material-symbols-outlined">
																			delete
																		</span>
																	</button>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										);
									})
								)}

								<div className="Add_legs">
									<button
										type="button"
										className="add_lgs_button"
										onClick={() => {
											setOrders((orders) => {
												return [...orders, order];
											});
										}}
									>
										Add Legs
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Secondsection;
