import React from 'react';
import '../css/Mystrategies.css';
import {
	FaList,
	FaGripHorizontal,
	FaUndoAlt,
	FaMarker,
	FaRegHeart,
	FaFile,
	FaFolderPlus,
	FaTrashAlt,
} from 'react-icons/fa';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ApiHandler from '../Api/ApiHandler';

const Mystratagies = ({ setBaskets }) => {
	const [liststyle, setListstyle] = useState('list');
	const [strategies, setStrategies] = useState([]);
	const navigate = useNavigate();


	const [rnum, setRnum] = useState(0);

	window.addEventListener('resize', () => {
		if (window.innerWidth <= 950) {
			setListstyle('grid');
		} else {
			setListstyle('list');
		}
	});
	const datafetch = () => {
		getStrategy();
	};
	const gridset=(width)=>{
				if (window.innerWidth <= 950) {
					setListstyle('grid');
				} else {
					setListstyle('list');
				}
	}

	useEffect(() => {
		setInterval(() => {
			datafetch();
		}, 1000);
		gridset(window.innerWidth);


		getStrategy();
	}, []);
	const basketdetail = (id) => {
		navigate({ pathname: '/Strategy_Details_Page', search: `?id=${id}` });
	};
	// navigate({ pathname: '/Strategy_Details_Page', search: `?id=${id}` });

	const getStrategy = async () => {
		const StrategyResp = await ApiHandler.getStrategy();
		if (!StrategyResp.ok) return console.log('Error', StrategyResp);
		const strategyData = StrategyResp.data;

		if (strategyData.length > 0) {
			setStrategies(strategyData);
			console.log(strategyData);
		}

		// console.log(strategyData);
	};

	return (
		<div className="strategies_container">
			<button className="create_btn">Create Strategy +</button>
			<div className="grid_icons">
				<div
					className="bar_icon"
					style={
						liststyle === 'list' ? { backgroundColor: 'rgb(255, 196, 0)' } : {display:"none"}
					}
					onClick={() => {
						setListstyle('list');
					}}
				>
					{' '}
					<FaList />
				</div>
				<div
					className="grid_icon"
					style={
						liststyle === 'grid' ? { backgroundColor: 'rgb(255, 196, 0)' } : {display:"none"}
					}
					onClick={() => {
						setListstyle('grid');
					}}
				>
					<FaGripHorizontal />
				</div>
			</div>

			<div
				className="list_view"
				style={
					liststyle === 'list' ? { display: 'block' } : { display: 'none' }
				}
			>
				<div className="list_parameters">
					<div className="list_name">Name & details</div>
					<div className="list_profit">Profit & loss</div>
					<div className="list_instruments">Instruments</div>
					<div className="list_creditedat">Credited at</div>
					<div className="list_status">Status</div>
					<div className="list_actions">Actions</div>
				</div>

				{strategies.length === 0 ? (
					<h3>No Strategies created yet.</h3>
				) : (
					strategies.map((item) => {
						return (
							<div
								className="list_parameters shado"
								onClick={() => {
									basketdetail(item.id);
								}}
							>
								<div className="list_name">
									<div className="clock_icon">
										<FaUndoAlt style={{ fontSize: '20px', margin: '5px' }} />
									</div>
									<div className="name_detail">
										<h5>
											{item.basket_name}{' '}
											<FaMarker style={{ marginLeft: '8px' }} />
										</h5>
										<p>
											<span>{item.scheduled_exec}</span>
											{''} - {''}
											<span>{item.scheduled_sqoff}</span>
										</p>
									</div>
								</div>
								<div className="list_profit">
									&#8377;{item.current_pnl.toFixed(2)}
								</div>
								<div
									className="list_instruments"
									style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
								>
									{item.indices}
								</div>
								<div
									className="list_creditedat"
									style={{ color: 'rgb(138, 134, 134)' }}
								>
									4 days ago
								</div>
								<div className="list_status">
									<div className="status_circle"></div>
									{item.status}
								</div>
								<div className="list_actions">
									<FaRegHeart
										style={{
											margin: '5px',
											color: 'rgb(138, 134, 134)',
											cursor: 'pointer',
										}}
									/>
									<FaFile
										style={{
											margin: '5px',
											color: 'rgb(138, 134, 134)',
											cursor: 'pointer',
										}}
									/>
									<FaFolderPlus
										style={{
											margin: '5px',
											color: 'rgb(138, 134, 134)',
											cursor: 'pointer',
										}}
									/>
									<FaTrashAlt
										style={{ color: 'red', margin: '7px', cursor: 'pointer' }}
									/>
								</div>
							</div>
						);
					})
				)}
			</div>

			<div
				className="grid_view"
				style={
					liststyle === 'grid' ? { display: 'block' } : { display: 'none' }
				}
			>
				<div className="strategy_container">
					{strategies.map((item) => {
						return (
							<div
								className="strategy_card"
								onClick={() => {
									basketdetail(item.id);
								}}
							>
								<div className="list_name">
									<div className="clock_icon">
										<FaUndoAlt style={{ fontSize: '20px', margin: '5px' }} />
									</div>
									<div className="name_detail">
										<h5>
											{item.basket_name}{' '}
											<FaMarker style={{ marginLeft: '8px' }} />
										</h5>
										<p>
											<span>{item.scheduled_exec}</span>
											{''} - {''}
											<span>{item.scheduled_sqoff}</span>
										</p>
									</div>
								</div>
								<div className="profit_status">
									<div className="list_profit">
										&#8377;{item.current_pnl.toFixed(2)}
									</div>
									<div className="list_status">
										<div className="status_circle"></div> {item.status}
									</div>
								</div>
								<div className="created_actions">
									<div
										className="list_creditedat"
										style={{ color: 'rgb(138, 134, 134)' }}
									>
										4 days ago
									</div>
									<div className="list_actions">
										<FaRegHeart
											style={{
												margin: '5px',
												color: 'rgb(138, 134, 134)',
												cursor: 'pointer',
											}}
										/>
										<FaFile
											style={{
												margin: '5px',
												color: 'rgb(138, 134, 134)',
												cursor: 'pointer',
											}}
										/>
										<FaFolderPlus
											style={{
												margin: '5px',
												color: 'rgb(138, 134, 134)',
												cursor: 'pointer',
											}}
										/>
										<FaTrashAlt
											style={{
												color: 'red',
												margin: '7px',
												cursor: 'pointer',
											}}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Mystratagies;
