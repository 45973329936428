import React from 'react';
import '../../css/Createstrtegy css/Firstsection.css';
import { useState } from 'react';

const Firstsection = ({ setStrategy, strategy }) => {
	const [basketbtn, setBasketbtn] = useState(true);
	const [dropdown, setDropdown] = useState(false);

	const changehandler = (e) => {
		console.log(e.target.value)
		setStrategy((strategy) => {
			return { ...strategy, [e.target.name]: e.target.value };
		});
	};
	return (
		<div>
			<div className="strategy_section">
				<div className="page_heading">
					<h4 className="strategy_heading">Create Strategy</h4>
					<div className="strategybutton">
						<button
							type="button"
							className="strategy_button"
							id="myDropdwon"
							onClick={() => setDropdown(dropdown === true ? false : true)}
						>
							Ready Made Strategies Template{' '}
						</button>
						<div
							className="sub_menu_warp"
							id="togglemenu"
							style={
								dropdown === true ? { display: 'block' } : { display: 'none' }
							}
						>
							<div className="sub_menu">
								<div className="search_box">
									<div className="search_filter">
										<input
											type="text"
											className="searchfield"
											placeholder="Search Strategies templates"
										/>
										<i className="material-icons manage-search">
											manage_search
										</i>
									</div>
								</div>
								<div className="common_component">
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Rsi Momentum</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Super Trend</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Stochastic Rsi</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Moving Average Crossover</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Heikinashi Very Bullish</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">
												Heikinashi Bullish Indecision
											</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">
												Banknifity Weekly- Macrosoption
											</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Bullish Marubozu</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">
												Banknifty Weekly Straddle positional Showacse
											</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Bullish Spinning Top</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Bullish Hammer</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">
												{' '}
												Banknifity Weekly Ironcodndor Positional Showacse
											</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">
												{' '}
												Banknifty Weekly -Macrossoveroptionbuy
											</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head"> Simple Moving Average </div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head"> Macd</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head"> Adx</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head"> Heikinashi Bullish</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head"> Bollinger Band</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Renko Bullish Brick</div>
										</div>
									</a>
									<a href="#" className="common_component_links">
										<div className="component_list">
											<div className="list_head">Atr</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="strategy">
					<div className="runtype_action">
						<div className="runtype_container">
							<label className="label_heading">Quant run type</label>
							<div role="group" className="btn_group">
								<button
									className={
										basketbtn === true ? 'btn_primary active' : 'btn_primary'
									}
									id="basket"
									name="runtype"
									value="paper"
									onClick={(e) => {
										setBasketbtn(true);
										changehandler(e);
									}}
								>
									Paper
								</button>
								<button
									className={
										basketbtn === true ? 'btn_primary' : 'btn_primary active'
									}
									id="live"
									name="runtype"
									value="live"
									onClick={(e) => {
										setBasketbtn(false);
										changehandler(e);
									}}
								>
									Live
								</button>
							</div>
						</div>
						<div
							className="datetype_section"
							id="dateModal"
							style={{ display: 'flex' }}
						>
							<div className="datetype_input">
								<label htmlFor="" className="label_heading">
									Start date
								</label>
								<div className="input_picker">
									<input
										type="date"
										name="start_date"
										className="form_controll"
										defaultValue="  "
										onChange={(e) => {
											changehandler(e);
										}}
									/>
								</div>
							</div>
							<div className="iconalign">-</div>
							<div className="datetype_input">
								<label className="label_heading">End date</label>
								<div className="input_picker">
									<input
										type="date"
										className="form_controll"
										name="end_date"
										defaultValue=""
										onChange={(e) => {
											changehandler(e);
										}}
									/>
								</div>
							</div>
						</div>
						{/* <div
							className="order_type"
							id="liveModal"
							style={
								basketbtn === true ? { display: 'none' } : { display: 'block' }
							}
						>
							<label className="order_title">Quant order Type</label>
							<div className="order_typesection">
								<div className="form_check">
									<input
										type="radio"
										className="form_check_input"
										defaultValue="paper"
										defaultChecked=""
										id="papertrading"
										name="ordertype"
										onClick={(e) => {
											changehandler(e);
										}}
									/>
									<label className="label_order" htmlFor="papertrading">
										Paper Trading
									</label>
								</div>
								<div className="form_check">
									<input
										type="radio"
										className="form_check_input"
										defaultValue="oneclick"
										id="oneclicktrading"
										name="ordertype"
										onClick={(e) => {
											changehandler(e);
										}}
									/>
									<label className="label_order" htmlFor="oneclicktrading">
										Oneclick Trading
									</label>
								</div>
								<div className="form_check">
									<input
										type="radio"
										className="form_check_input"
										defaultValue="automatic"
										id="autotrading"
										name="ordertype"
										onClick={(e) => {
											changehandler(e);
										}}
									/>
									<label className="label_order" htmlFor="autotrading">
										Auto Trading
									</label>
								</div>
							</div>
						</div> */}
						<div className="runname_container">
							<div className="runname_input">
								<label className="label_heading">Quant run name</label>
								<div className="input-warppper">
									{/* <i class="material-symbols-outlined">draft</i> */}
									<input
										type="text"
										className="input_form_controll"
										placeholder="Enter the name"
										value={strategy.basket_name}
										name="basket_name"
										onChange={(e) => {
											changehandler(e);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="folder_container">
							<div className="folder_input">
								<label className="label_heading">Quant folder name</label>
								<div className="folder-warppper">
									{/* <i class="material-symbols-outlined">draft</i> */}
									<input
										type="text"
										className="input_controll disabled"
										placeholder="Select a folder Create a new"
										disabled=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Firstsection;
