import './App.css';
import BasePage from './Screens/BasePage';
import Basepage2 from './Screens/Basepage2';


function App() {
	return <Basepage2 />;
}

export default App;
