import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import Mystratagies from '../components/Mystratagies';
import Createstrategy from '../components/Createstrategy';
import Marketplace from '../components/Marketplace';
import { NavLink } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Strategypopup from '../components/Strategypopup';
import Login from '../components/Login';
import { useState } from 'react';

const Basepage2 = () => {
	return (
		<div>
			<div className="navba" id="navbar-example2">
				<div className="navba-l">
					<div>
						<a href="">
							<h1 style={{ color: 'white' }}>E.D.I.TH</h1>
						</a>
					</div>
					<div>
						<ul style={{ marginBottom: 0 }}>
							<li>
								<NavLink to="/">DashBoard</NavLink>
							</li>
							<li>
								<NavLink to="/Mystrategies">Mystrategies</NavLink>
							</li>
							<li>
								<NavLink to="/Createstrategy">Create Strategy</NavLink>
							</li>
							<li>
								<NavLink to="/Marketplace">MarketPlace</NavLink>
							</li>
							<li>
								<NavLink to="/Videos">Videos</NavLink>
							</li>
							<li>
								<a href="#">Faq</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="navba-r">
					<ul style={{ marginBottom: 0 }}>
						<li>
							<a href="#" className="buyPlan">
								Buy a Plan{' '}
							</a>
						</li>
						<li>
						<NavLink to="/login">Login</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<div
				// data-bs-spy="scroll"
				// data-bs-target="#navbar-example2"
				data-bs-offset={0}
				// className="scrollspy-example"
				tabIndex={0}
			></div>

			<Routes>
				<Route path="/" />
				<Route path="/Mystrategies" element={<Mystratagies />} />
				<Route path="/Createstrategy" element={<Createstrategy />} />
				<Route path="/Marketplace" element={<h3>market place</h3>} />
				<Route path="/Strategy_Details_Page" element={<Strategypopup />} />
				<Route path="/login" element={<Login />} />
			</Routes>
			<nav className="mobile-bottom-nav hide-minimal-content">
				<div className="bottom-nav-item active">
					<NavLink to="/">
						<i className="material-icons-outlined">home</i>
						<p>Dashboard</p>
					</NavLink>
				</div>
				<div className="bottom-nav-item">
					<NavLink to="/Mystrategies">
						{' '}
						<i className="material-icons-outlined">source </i>
						<p>My Strategies</p>
					</NavLink>
				</div>
				<div className="bottom-nav-item">
					<NavLink to="/Createstrategy">
						<i className="material-icons-outlined">addchart</i>
						<p>Create Strategy</p>
					</NavLink>
				</div>
				<div className="bottom-nav-item">
					<NavLink to="/Marketplace">
						<i className="material-icons">storefront</i>
						<p>MarketPlace</p>
					</NavLink>
				</div>
				<div className="bottom-nav-item position-static">
					<div
						className="bottom-nav-link dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i className="material-icons">more_vert</i>
						More
					</div>
				</div>
			</nav>
			<div className="ft-section">
				<div className="footer-section ">
					<div className="footer-social">
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://png.pngtree.com/element_our/png_detail/20181011/facebook-social-media-icon-design-template-vector-png_127003.png"
									alt="facebook"
								/>
								<div className="sub-text">Follow Us</div>
							</div>
						</a>
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://images.vexels.com/media/users/3/137419/isolated/lists/b1a3fab214230557053ed1c4bf17b46c-twitter-icon-logo.png"
									alt="twitter"
								/>
								<div className="sub-text">Follow Us</div>
							</div>
						</a>
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png"
									alt="telegram"
								/>
								<div className="sub-text">Join Us</div>
							</div>
						</a>
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://i.pinimg.com/736x/33/77/42/33774243261bd5d796a3b6087636cbd0.jpg"
									alt="whatsapp"
								/>
								<div className="sub-text">Join Us</div>
							</div>
						</a>
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://img.favpng.com/10/23/11/youtube-portable-network-graphics-logo-image-computer-icons-png-favpng-5k5DNc5DBpxFXsqScWJ07n9iV.jpg"
									alt="youtube"
								/>
								<div className="sub-text">Subscribe Us</div>
							</div>
						</a>
						<a href="#" target="_blank" className="social-link">
							<div className="social-inner">
								<img
									src="https://e7.pngegg.com/pngimages/511/242/png-clipart-email-graphic-design-computer-icons-email-miscellaneous-angle.png"
									alt="email"
								/>
								<div className="sub-text font-weight-semi">
									support@quantman.in
								</div>
							</div>
						</a>
					</div>
					<div className="footer-link">
						<a href="#">Terms &amp; Conditions</a>
						<span>|</span>
						<a>
							<u>Faq</u>
						</a>
						<span>|</span>
						<a>About Us</a>
						<span>|</span>
						<span className="text-muted">
							Copyright © 2022, Quantman. All rights reserved.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Basepage2;
