import React, { useEffect } from 'react';
import '../css/Strategypopup.css';
import ToggleSwitch from './toggleswitch/ToggleSwitch';
import {
	FaChevronDown,
	FaChevronUp,
	FaChartLine,
	FaUndoAlt,
	FaPen,
	FaRegHeart,
	FaFile,
	FaFolderPlus,
	FaTrashAlt,
	FaPowerOff,
	FaFacebook,
	FaTwitter,
	FaSatelliteDish,
	FaRegListAlt,
	FaCheckSquare,
} from 'react-icons/fa';
import { useState } from 'react';
import ApiHandler from '../Api/ApiHandler';
import { useSearchParams } from 'react-router-dom';

const Strategypopup = () => {
	const [basketpopup, setBasketpopup] = useState(null);
	const [orders, setOrders] = useState([]);
	const [baskets, setBaskets] = useState([]);
	const [basketid, setBasketid] = useState(null);
	const switchbasket = (n) => {
		if (basketpopup === n) {
			setBasketpopup(null);
		} else {
			setBasketpopup(n);
		}
	};
	const [searchParams, setSearchParams] = useSearchParams();
	var strategy_id = searchParams.get('id');

	useEffect(() => {
		setInterval(() => {
			// basketcall(+strategy_id);
			getBasketData();
		}, 2000);
	}, []);

	const getBasketData = async () => {
		const response = await ApiHandler.getBasket();
		if (!response.ok) return console.log(response);
		var BasketFiltered = [];
		for (let b = 0; b < response.data.length; b++) {
			if (response.data[b].Stratedy_id.id == strategy_id) {
				BasketFiltered = [...BasketFiltered,response.data[b]];
			}
		}
		getOrderData(BasketFiltered);
	};

	const getOrderData = async (basketData) => {
		const response = await ApiHandler.getOrder();
		if (!response.ok) return console.log(response);
		megeOutput(basketData, response.data);
	};

	const megeOutput = (basketData, orderData) => {
		for (let b = 0; b < basketData.length; b++) {
			var OrderFiltered = [];
			for (let o = 0; o < orderData.length; o++) {
				console.log(basketData);
				if (orderData[o].basket_id.id === basketData[b].id) {
					OrderFiltered = [...OrderFiltered, orderData[o]];
				}
			}
			basketData[b]['orders'] = OrderFiltered;
		}

		// setBaskets(basketData);

		setBaskets(basketData);
		console.log(baskets);
	};
	console.log(baskets);

	return (
		<div className="strategydetail_container">
			<div className="heading">
				<h5>
					BNK 250 @ 25 % SL 2L <FaPen style={{ marginLeft: '10px' }} />
				</h5>
				<div className="detail_btns">
					<button className="details_btn restart_btn">
						<FaPowerOff style={{ marginRight: '6px' }} />
						Restart
					</button>
					<button className="details_btn copy">view Strtegy details</button>
				</div>
			</div>

			{baskets.length === 0 ? (
				<p>no baskets available</p>
			) : (
				<div className="details_parameters">
					<div className="current_parameter">
						<div className="details_icon">
							<FaChartLine style={{ color: 'green' }} />
						</div>
						<div className="details_name">
							<p>completed transaction profit</p>
							<span> &#8377; {baskets[0].Pnl.toFixed(2)}</span>
						</div>
					</div>
					<div className="current_parameter">
						<div className="details_icon">
							<FaSatelliteDish style={{ color: 'orange' }} />
						</div>
						<div className="details_name">
							<p>Run Type</p>
							<span>{baskets[0].Stratedy_id.runtype}</span>
						</div>
					</div>

					<div className="current_parameter">
						<div className="details_icon">
							<FaRegListAlt style={{ color: 'blue' }} />
						</div>
						<div className="details_name">
							<p>Order Type</p>
							<span>automatic</span>
						</div>
					</div>

					<div className="current_parameter">
						<div className="details_icon">
							<FaCheckSquare style={{ color: 'green' }} />
						</div>
						<div className="details_name">
							<p>Status</p>
							<span>{baskets[0].Stratedy_id.status}</span>
						</div>
					</div>
				</div>
			)}

			{baskets.length == 0 ? (
				<></>
			) : (
				<div class="accordion mt-4" id="accordionExample">
					{baskets.map((item, id) => {
						return (
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne">
									<button
										style={{
											background:
												item.status == 'Active' ? '#36ff9a' : '#f7f7f7',
										}}
										class="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target={'#collapseOne_' + id}
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										{item.basket_name} # {id} {item.Stratedy_id.indices}{' '}
										{'Rs. '} {item.Pnl.toFixed(2)}
									</button>
								</h2>
								<div
									id={'collapseOne_' + id}
									class={
										'accordion-collapse collapse ' +
										(item.status == 'Active' ? 'show' : null)
									}
									aria-labelledby="headingOne"
									data-bs-parent="#accordionExample"
								>
									<div className="table_data">
										<table>
											<tbody>
												<tr>
													<th>Trading symbol</th>
													<th>Trading id</th>
													<th>Type</th>
													<th>signal/ordertype</th>
													<th>Qty</th>
													<th>Entry price/Time</th>
													<th>Profit and Loss</th>
													<th>Traded price/Time</th>
												</tr>
												{item.orders.length === 0 ? (
													<h3>No orders placed yet.</h3>
												) : (
													item.orders.map((item) => {
														return (
															<tr>
																<td>{item.instrument_name}</td>
																<td>{item.instrument_id}</td>
																<td>
																	<span
																		className={
																			item.leg_type === 'sell'
																				? 'sell_buy sell'
																				: 'sell_buy buy'
																		}
																	>
																		{item.leg_type}
																	</span>
																</td>
																<td>Entry</td>
																<td>{item.qty}</td>
																<td>
																	{item.sellPrice}/{' '}
																	<span className="time">
																		{item.order_created_at}
																	</span>
																</td>
																<td>
																	<span className="time">
																		{item.pnl.toFixed(2)}
																	</span>
																</td>
																<td>
																	<div>₹ 63302.25</div>
																</td>
															</tr>
														);
													})
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Strategypopup;
