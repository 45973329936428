import React from 'react';
import '../css/Login.css';
import { useState } from 'react';
import ApiHandler from '../Api/ApiHandler';

import { useNavigate } from 'react-router-dom';

const Login = () => {
	const navigate = useNavigate();
	const [user, setUser] = useState({
		username: 'admin',
		password: '12345',
	});
	const changehandler = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handleLogin = async () => {
		const response = await ApiHandler.login(user);
		if(!response.ok) return console.log(response.data);
		console.log(response.data);
		// navigate({ pathname: '/Mystrategies'});
	};

	// console.log(user);
	return (
		<div className="loginpage">
			<div className="user_cred">
				<div className="user_input">
					<p>user name</p>
					<input
						type="text"
						placeholder="username"
						value={user.username}
						name="username"
						onChange={(e) => {
							changehandler(e);
						}}
					/>
				</div>
				<div className="user_input">
					<p>password</p>
					<input
						type="text"
						placeholder="password"
						name="password"
						value={user.password}
						onChange={(e) => {
							changehandler(e);
						}}
					/>
				</div>
				<div className="sub_btn">
					<button
						className="btn btn-primary"
						style={{ marginLeft: 'auto' }}
						onClick={() => handleLogin()}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};

export default Login;
