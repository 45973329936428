import Client from './Client';

const login = (credentials) => Client.post('edith_api/login/', credentials);

const getStrategy = () => Client.get('edith_api/strategy/');

const getBasket = () => Client.get('edith_api/basket/');

const getOrder = () => Client.get('edith_api/order/');

export default {
	getStrategy,
	getBasket,
	getOrder,
	login,
};
