import React from 'react';
import '../../css/Createstrtegy css/Thirdsection.css';
import { useState } from 'react';
import { FaCheckDouble, FaRegClock } from 'react-icons/fa';


const Thirdsection = ({ setStrategy, strategy }) => {
	const [content_type, setContent_type] = useState(1);
	const [expiry_type, setExpiry_type] = useState('autoexpiry');
	const [exechh, setExechh] = useState(0);
	const [execmm, setExecmm] = useState(0);
	const [sqoffhh, setsqoffhh] = useState(0);
	const [sqoffmm, setsqoffmm] = useState(0);

	const schedule = (e) => {
		if (e === 'scheduled_exec') {
			setStrategy({ ...strategy, [e]: `${exechh}:${execmm}` });
		} else {
			setStrategy({ ...strategy, [e]: `${sqoffhh}:${sqoffmm}` });
		}
	};

	return (
		<div className="Strategy_expiry">
			<h3>Expiry</h3>
			<div className="strategy_type_btn">
				<p style={{ fontSize: '12px', fontWeight: 700 }}>Strategy type</p>
				<div className="startegy_type_buttons">
					<button
						id="intraday"
						className={
							content_type === 1 ? 'str_btn active font' : 'str_btn font'
						}
						onClick={() => {
							setContent_type(1);
							setStrategy({ ...strategy, ['intra_mis']: 'mis' });
						}}
					>
						Intraday
					</button>
					<button
						id="positional"
						className={
							content_type === 1 ? 'str_btn font ' : 'str_btn active font'
						}
						onClick={() => {
							setContent_type(2);
							setStrategy({ ...strategy, ['intra_mis']: 'nrml' });
						}}
					>
						Positionl
					</button>
				</div>
				<div></div>
			</div>
			<div
				className="intraday_content"
				style={content_type === 1 ? { display: 'block' } : { display: 'none' }}
				id="intraday_content"
			>
				<div className="trade_during">
					<p style={{ fontSize: '12px', fontWeight: 700 }}>Trade During</p>
					<div className="trade_time">
						<div className="trade_start">
							<i
								className="far fa-clock"
								style={{ padding: '3px 0px 3px 8px' }}
							/>
							&nbsp;
							<input
								type="number"
								max={23}
								min={0}
								value={exechh}
								className="trade_time_input font"
								onChange={(e) => {
									console.log(e.target.value);
									if (e.target.value > 23) {
										alert('please enter the valid time');
										FaCheckDouble();
									} else {
										setExechh(e.target.value);
										setStrategy({
											...strategy,
											['scheduled_exec']: `${e.target.value}:${execmm}`,
										});
									}
								}}
							/>
							<span>:</span>
							<input
								type="number"
								max={59}
								min={0}
								value={execmm}
								className="trade_time_input font"
								placeholder="mm"
								onChange={(e) => {
									if (e.target.value > 59) {
										alert('please enter the valid time');
									} else {
										setExecmm(e.target.value);
										setStrategy({
											...strategy,
											['scheduled_exec']: `${exechh}:${e.target.value}`,
										});
									}
								}}
							/>
						</div>
					</div>
					&nbsp; - &nbsp;
					<div className="trade_end">
						<i
							className="far fa-clock"
							style={{ padding: '3px 0px 3px 8px' }}
						/>
			
						<input
							type="number"
							max={23}
							min={0}
							className="trade_time_input font"
							placeholder="hh"
							value={sqoffhh}
							onChange={(e) => {
								console.log(sqoffhh);
								if (e.target.value > 23) {
									alert('please enter the valid time');
								} else {
									setsqoffhh(e.target.value);
									setStrategy({
										...strategy,
										['scheduled_sqoff']: `${e.target.value}:${sqoffmm}`,
									});
								}
							}}
						/>
						<span>:</span>
						<input
							type="number"
							max={59}
							min={0}
							value={sqoffmm}
							className="trade_time_input font"
							placeholder="mm"
							name="mm"
							onChange={(e) => {
								if (e.target.value > 59) {
									alert('please enter the valid time');
								} else {
									setsqoffmm(e.target.value);
									setStrategy({
										...strategy,
										['scheduled_sqoff']: `${sqoffhh}:${e.target.value}`,
									});
								}
							}}
						/>
					</div>
				</div>
				<div className="max_transaction">
					<p style={{ fontSize: '12px', fontWeight: 700 }}>
						Maximum transaction per day
					</p>
					<input
						type="number"
						placeholder="Number of Transaction"
						className="font"
						style={{ padding: '4px 10px' }}
						onChange={(e) => {
							setStrategy({
								...strategy,
								['no_of_transactions']: +e.target.value,
							});
						}}
					/>
				</div>
			</div>
			<div
				className="positional_content"
				style={content_type === 1 ? { display: 'none' } : {}}
				id="positional_content"
			>
				<p>Expiry Handling</p>
				<div className="radio_buttons">
					<input
						type="radio"
						id="autoexpiry"
						defaultValue="autoexpiry"
						name="expiry"
						defaultChecked=""
					/>
					<label htmlFor="Autoexpiry">Auto Expiry</label>
					<input
						type="radio"
						id="manualexpiry"
						name="expiry"
						defaultValue="manualexpiry"
					/>
					<label htmlFor="Manualexpiry">Manual Expiry</label>
					<div id="auto_expiry" className="auto_expiry">
						<p>On Expiry Exit At </p>
						<div className="trade_time">
							<div className="trade_start">
								<i
									className="far fa-clock"
									style={{ padding: '3px 0px 3px 8px' }}
								/>
								&nbsp;
								<input
									type="number"
									max={23}
									min={0}
									className="trade_time_input font"
									placeholder="hh"
								/>
								<span>:</span>
								<input
									type="number"
									max={59}
									min={0}
									className="trade_time_input font"
									placeholder="mm"
								/>
							</div>
						</div>
					</div>
					<div id="manual_expiry_content">
						<div className="manual_expiry">
							<input
								type="number"
								style={{ width: 30, height: '90%', outline: 'none' }}
								className="font"
							/>
							<p> Force Exit At </p>
						</div>
						<div className="manual_expiry">
							<p> Days Before Expiry </p>
							<div className="trade_time">
								<div className="trade_start">
									<i
										className="far fa-clock"
										style={{ padding: '3px 0px 3px 8px' }}
									/>
									&nbsp;
									<input
										type="number"
										max={23}
										min={0}
										className="trade_time_input font"
										placeholder="hh"
									/>
									<span>:</span>
									<input
										type="number"
										max={59}
										min={0}
										className="trade_time_input"
										placeholder="mm"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Thirdsection;
