import React from 'react';
import Firstsection from './Create strategy components/Firstsection';
import Secondsection from './Create strategy components/Secondsection';
import Thirdsection from './Create strategy components/Thirdsection';

import '../css/Createstrategy.css';
import { useState } from 'react';

const Createstrategy = () => {
	const [orders, setOrders] = useState([
		{
			type: 'ATM',
			strick_qty: null,
			stp_perc: null,
			strick_type: 'CE',
			order_type: null,
			strike_expiry: null,
		},
	]);
	const [strategy, setStrategy] = useState({
		id: 1,
		basket_name: 'NOV 09',
		start_date: '',
		end_date: '',
		segments: 'nfo',
		isScheduled: false,
		ispower_cool: false,
		re_entry: false,
		move_sl_to_cost: false,
		indices: 'BANKNIFTY',
		// sq_target: 0.0,
		// stop_loss: 0.0,
		// target_strike: 0.0,
		// init_target: 0.0,
		scheduled_exec: '00:00',
		scheduled_sqoff: '00:00',
		intra_mis: 'mis',
		status: 'Scheduled', //default 1scheduled
		runtype: 'Paper',
		no_of_transactions: 0,
		// cumulative_pnl: 0.0,
		// current_pnl: 3925.0,
		user_id: 1, //default 1
	});
	// console.log(strategy);
	console.log(orders);
	const submitstrategy=()=>{
		let strategydata={...strategy,["orders"]:orders}
		console.log(strategydata);
	}

	return (
		<div className="create_body">
			<Firstsection setStrategy={setStrategy} strategy={strategy} />
			<Secondsection
				setStrategy={setStrategy}
				strategy={strategy}
				setOrders={setOrders}
				orders={orders}
			/>
			<Thirdsection setStrategy={setStrategy} strategy={strategy} />
			<div className='submit_str'>
				<button className='btn btn-primary' onClick={submitstrategy}>Submit</button>
			</div>
		</div>
	);
};

export default Createstrategy;
